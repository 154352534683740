<template>
  <LxpMobileHeader :title="toggle.previewModal ? '미리보기' : '글쓰기'">
    <template v-slot:left>
      <div class="util util-history-back">
        <a v-if="toggle.previewModal" @click="toggle.previewModal = !toggle.previewModal" class="util-actions util-actions-history-back">
          <i class="icon-history-back"></i>
        </a>
        <a v-else @click="goBack" class="util-actions util-actions-history-back">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="!toggle.previewModal" class="util header-util-more" @click.stop="toggle.mobileSaveBtn = !toggle.mobileSaveBtn">
        <button class="dropdown-btn"><i class="icon-more"></i></button>
        <div class="dropdown" :class="{'is-active' : toggle.mobileSaveBtn}">
          <div class="dropdown-target" style="left: unset; right: -10px;">
            <div class="dropdown-box">
              <ul class="dropdown-option-list">
                <li v-if="isHotTipBoard" class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="clickSaveTemp"><span class="dropdown-option-text">임시저장</span></a>
                </li>
                <li class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="showPreviewModal"><span class="dropdown-option-text">미리보기</span></a>
                </li>
                <li v-if="!isReadOnly" class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="clickSave"><span class="dropdown-option-text">등록</span></a>
                </li>
                <li v-if="param.stt == '01'" class="dropdown-option-item">
                  <a class="dropdown-option-link" @click="deletePost(param.comPostSn, exitBoardPage)"><span class="dropdown-option-text">삭제</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LxpMobileHeader>

<!--  미리보기 -->
  <template v-if="toggle.previewModal">
    <BoardPreviewModal :show="toggle.previewModal" :board-mst="boardMst" :post="param" :files="files.files" :custom-infos="customInfos" />
  </template>
<!--  글쓰기 화면 -->
  <!-- begin::kb-main -->
  <main v-else class="kb-main" id="kb-helpdesk-editor">
    <div class="board-editor-container">
      <div class="editor-body">
        <div v-if="!isLoading" class="d-flex flex-column gap-2">
          <div class="kb-form-row">
            <input type="text" v-model="param.postTitle" class="kb-form-control" placeholder="제목을 입력하세요" :readonly="isReadOnly" maxlength="90">
          </div>
          <div class="kb-form-row" v-if="boardMst.userScrtYn == 'Y'">
            <input type="text" v-model="param.userNickNm" class="kb-form-control" placeholder="닉네임을 입력하세요" :readonly="isReadOnly" maxlength="50">
          </div>
          <div class="kb-form-row" v-if="boardCateList.length > 1">
            <div class="kb-form-dropdown" :class="{'is-active' : (isCateToggle && !isReadOnly) }">
              <button class="kb-form-dropdown-btn" @click="isCateToggle = !isCateToggle"><strong class="text">{{ cateNm }}</strong><i class="icon-arrow"></i></button>
              <div class="kb-form-dropdown-options">
                <div class="dropdown-option">
                  <ul class="dropdown-option-list">
                    <li v-for="item in boardCateList" :key="item.boardCateSn" class="dropdown-option-item">
                      <a class="dropdown-option-link" @click="clickCategory(item)"><span class="dropdown-option-text">{{item.cateNm}}</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="kb-form-row" v-if="boardMst.postTopFixYn === 'Y' && param.postLvl === 0">
            <span class="text-muted fs-8">게시판 상단 고정</span>
            <div>
              <div class="kb-form-check kb-form-check-inline">
                <input type="radio" class="kb-form-check-input" id="top_fix_y" v-model="param.topFixYn" value="Y" :disabled="isReadOnly"/>
                <label for="top_fix_y" class="kb-form-check-label"><span class="text">설정</span></label>
              </div>
              <div class="kb-form-check kb-form-check-inline">
                <input type="radio" class="kb-form-check-input" id="top_fix_n" v-model="param.topFixYn" value="N" :disabled="isReadOnly"/>
                <label for="top_fix_n" class="kb-form-check-label"><span class="text">미설정</span></label>
              </div>
            </div>
          </div>
          <board-custom-info v-model:custom-infos="customInfos" :is-read-only="isReadOnly" v-if="customInfos.length > 0"/>
          <div class="kb-form-row" v-if="boardMst.postHashTagYn === 'Y'">
            <span class="text-muted fs-8">해시태그</span>
            <div class="d-flex justify-content-between">
              <input type="text" v-model="hashTag" class="kb-form-control" placeholder="단어 입력 후 등록 버튼 클릭" :readonly="isReadOnly">
              <button class="kb-btn kb-btn-xs ms-2" :class="hashTag ? 'kb-btn-primary' : 'kb-btn-secondary'" @click="addHashTag"><span class="text">등록</span></button>
            </div>
            <template v-for="(tag, idx) in param.hashTag.split(hashTagSeparator)" :key="idx">
              <div v-if="tag" class="badge badge-brown my-1 me-1">
                <span class="badge-text me-1">{{tag}}</span>
                <button @click="removeHashTag(idx)"><i class="icon-tag-delete"></i></button>
              </div>
            </template>
          </div>
        </div>
        <div class="editor-contents">
          <div class="content-body">
            <div ref="pageEditor" />
          </div>
          <div class="content-footer border-top" v-if="!isLoading && boardMst.postNoti">
            <pre class="my-3 text-muted lh-base" style="white-space: pre-wrap;">{{boardMst.postNoti}}</pre>
          </div>
        </div>
        <div v-if="!isLoading" class="d-flex flex-column mt-2 gap-2">
          <div class="kb-form-row" v-if="boardMst.boardLayoutCd == 'thumbList'">
            <div class="file-upload">
              <div class="kb-form-file" title="썸네일 파일 등록">
                <label for="thumb-file" class="kb-form-file-btn kb-btn kb-btn-secondary">
                  <span class="text" v-if="param.thumbFileNm == ''">썸네일 이미지 등록</span>
                  <span class="text" v-else >{{param.thumbFileNm}}</span>
                </label>
                <input type="file" ref="uploadThumbFile" @change="clickThumb" class="kb-form-file-input kb-mouse-cursor" id="thumb-file" :disabled="isReadOnly">
              </div>
            </div>
          </div>
          <div class="kb-form-row" v-if="boardMst.atchMaxCnt > 0">
            <div class="file-upload">
              <div class="kb-form-file">
                <label for="inp-file" class="kb-form-file-btn kb-btn kb-btn-secondary"><span class="text">파일첨부</span></label>
                <input type="file" ref="uploadFiles" @change="clickFileAtch" class="kb-form-file-input kb-mouse-cursor" id="inp-file" :disabled="isReadOnly">
              </div>
              <p class="kb-form-file-tip">파일첨부는 최대 {{boardMst.atchMaxCnt}}개까지 가능합니다.</p>
            </div>
            <ul class="p-3 mt-2 border" v-if="files.files.length > 0">
              <li v-for="(item, idx) in files.files" :key="idx">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-inline-flex">
                    <span>{{item.fileNm}}</span>
                    <span class="text-muted ms-2">{{convertToStorageBytes(item.fileSz)}}</span>
                  </div>
                  <button type="button" @click="removeFile(item)" class="ms-1" :disabled="isReadOnly"><i class="icon-x"></i></button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- end::kb-main -->
  <div v-if="isUploading" class="fixed-top bg-dark opacity-75 d-flex flex-column justify-content-center align-items-center vw-100 vh-100">
    <LoadingDiv />
    <h3 class="text-primary fs-5">썸네일 이미지 업로드 중입니다.</h3>
  </div>
</template>

<script>
import {boardEditSetup} from "@/assets/js/modules/board/board-common";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import BoardCustomInfo from "@/components/board/BoardCustomInfo";
import BoardPreviewModal from "@/components/board/BoardPreviewModal.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";

export default {
  name: 'BoardEdit',
  components: {LxpMobileHeader, BoardCustomInfo, BoardPreviewModal, LoadingDiv},
  setup: boardEditSetup
}
</script>